import React from 'react';
import styled from 'styled-components';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { useSelector } from 'react-redux';
import { showActiveNRegisteredClientIds } from '../Util/constants';

const ActivityLevelProgressBar = ({ active_employees, inactive_employees, registered, onBoarded }) => {
  let total_employees = active_employees + inactive_employees;
  const profileData = useSelector((store) => store.company?.profile);
  let adoption_rate = showActiveNRegisteredClientIds.includes(profileData?.id)
    ? (active_employees / registered) * 100
    : (registered / onBoarded) * 100;

  return (
    <Wrapper>
      <FlexRow>
        <TextWrapper>Adoption Rate :</TextWrapper>
        <TextWrapper style={{ color: '#00c8a0', fontWeight: 600 }}>
          {isNaN(adoption_rate) ? '0' : adoption_rate.toFixed(2)}%
        </TextWrapper>
      </FlexRow>
      {isNaN(total_employees) ? (
        <TextWrapper>No Users Found</TextWrapper>
      ) : (
        <ProgressBarWrapper style={{ width: '100%' }}>
          <ProgressBar
            height={50}
            // showTextIn
            outlineColor="black"
            id="new_id"
            fontColor="black"
            data={[
              {
                name: '',
                value: showActiveNRegisteredClientIds.includes(profileData?.id) ? active_employees : registered,
                description: 'registered',
                color: '#00c8a0',
              },
              {
                name: '',
                value: showActiveNRegisteredClientIds.includes(profileData?.id)
                  ? registered - active_employees
                  : onBoarded - registered,

                description: 'onBoarded',
                color: '#bababa',
              },
            ]}
          />
        </ProgressBarWrapper>
      )}
      Registered Users: {registered}
      <ScaleWrapper>
        <FlexRow>
          <Box style={{ backgroundColor: '#00c8a0' }} />
          <TextWrapper>
            {showActiveNRegisteredClientIds.includes(profileData?.id)
              ? `Active Users: ${active_employees}`
              : `Registered Users: ${registered}`}
          </TextWrapper>
        </FlexRow>
        <FlexRow>
          <Box style={{ backgroundColor: '#bababa' }} />
          <TextWrapper>
            {showActiveNRegisteredClientIds.includes(profileData?.id)
              ? `Registered Users: ${registered}`
              : `Onboarded Users: ${onBoarded}`}
          </TextWrapper>
        </FlexRow>
      </ScaleWrapper>
    </Wrapper>
  );
};

const ProgressBarWrapper = styled.div`
  width: 100%;
  & #new_id {
    border-radius: 10px;
  }
`;

const ProgressBar = styled(HSBar)`
  & #new_id {
    border-radius: 10px;
  }
`;

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

const Box = styled.div`
  width: 20px;
  height: 20px;
`;

const TextWrapper = styled.div`
  color: grey;
  font-size: 15px;
`;

const ScaleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export default ActivityLevelProgressBar;
