import React from 'react';
import styled from 'styled-components';

import { AuthLayout } from '../../Layout';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';

const Login = (props) => {
  const LoginInfo = localStorage.getItem('auth');
  const loggedIn = useSelector((store) => store.auth.loggedIn);
  const { t } = useTranslation();
  if (LoginInfo && JSON.parse(LoginInfo).role === 'company' && loggedIn) return <Redirect to="/dashboard" />;

  return (
    <AuthLayout heading={t("loginToAccount")}>
      <Wrapper>
        <LoginForm userType="company" redirect="/dashboard" {...props} />
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  //text-align: center;
  width: 100%;
`;
export default Login;
