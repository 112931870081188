import axios from 'axios';

const api = {
  auth: {
    login: (userType, data) => axios.post(`${userType}/login`, data),
    adminLogin: (userType, data) => axios.post(`/${userType}/company-login`, data),
    signup: (userType, data) => axios.post(`${userType}/signup`, data),
    forgotPassword: (data) => axios.post('/forgot_password', data),
    verifyEmail: (token) => axios.post('/verify', { verify_token: token }),
    resetPassword: (data) => axios.post('/reset_password', data),
    changePassword: (data) => axios.post('/change_password', data),
  },
  company: {
    getProfile: () => axios.get('/company/profile'),
    updateProfile: (data) => axios.post('/company/profile', data),
    createCompanyMasterUser: (data) => axios.post('/company/create_user', data),
    createSingleManager: (data) => axios.post('/company/create_user', data),
    createSingleEmployee: (data) => axios.post('/company/invite_user', data),
    createBulkManager: (data) => axios.post('/company/create_user', data),
    createBulkEmployee: (data) => axios.post('/company/invite_user', data),
    getDailyMoods: () => axios.get('/company/daily_moods'),
    getDashboardBoxes: () => axios.get('/company/dashboard'),
    getLeadingIssues: (data) =>
      axios.get(
        '/company/moods_dashboard',
        data && {
          params: { start_date: data.start, end_date: data.end, department_id: data.departmentId },
        },
      ),
    getOverallSatisfaction: () => axios.get('/company/feedback_stats'),
    getDashboardDepartmentStats: (start_date, end_date) =>
      axios.get(`/company/employee_department_stats?start_date=${start_date}&end_date=${end_date}`),
    getDashboardActivityOverTime: (start_date, end_date) =>
      axios.get(`/company/activity_over_time?start_date=${start_date}&end_date=${end_date}`),
    subscriptionStatus: () => axios.post('/company/subscription_status'),
    getDepartments: () => axios.get('/company/departments'),
    getLocations: (company_unique_id) => axios.get(`/company_branches?company_unique_id=${company_unique_id}`),
    getContract: () => axios.get('/company/contract'),
    getUserList: () => axios.get('company/users'),
    getManagerList: () => axios.get('company/users'),
    getEmployeeList: () => axios.get('company/employees'),
    getInvitedToHeartbeat: () => axios.get('company/survey/invited-employees'),
    getInvitedEmployeeList: () => axios.post('company/invited-users'),
    getInvitationDetails: () => axios.get('/company/authentication_details'),
    updateUserDetails: (data) => axios.post('/company/user_details', data),
    getUserDetails: () => axios.get('/company/user_details'),
    revokeAccess: (email) => axios.post('/v1/company/survey-revokeuser', { email }),
    revokeManagerAccess: (id) => axios.post('/company/delete_user', { user_id: id }),
    revokeEmployeeAccess: (email) => axios.post('/company/deactivate_employee', { email }),
    revokeHearBeatAccess: (email) => axios.post('/v1/company/survey-revokeuser', { email }),
    resendinvite: (id, language) => axios.post('/company/resent-invite', { id, language }),
    inviteEmployee: (formData) => axios.post('/company/invite_user', formData),
  },
  survey: {
    getQuestions: () => axios.get('/v1/company/get-survey-questions'),
    save: (data) => axios.post('/v1/company/save-survey-questions', data),
    edit: (data) => axios.post('/v1/company/update_survey', data),
    changeSurveyStatus: (data) => axios.post('/v1/company/change_survey_status', data),
    exportSurvey: (id) => axios.get(`/v1/company/export-survey?survey_id=${id}`),
    send: (data) => axios.post('/v1/company/send-survey', data),
    getSurveyOverview: (data) => {
      return axios.get(
        'v1/company/survey_overview',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            status: data.status,
            positive: data.positive,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      );
    },
    getSurveys: () => axios.get('/v1/company/company_surveys'),
    getQuestionnaire: (id) => axios.get(`/v2/get-survey?survey_id=${id}`),
    submitQuestionnaire: (data) => axios.post('v1/survey-response', data),
    isSurveySubmitted: (hash) => axios.post(`v1/is-survey-submitted`, { hash }),

    addBenefits: (data) => axios.post(`/company/add_benefit`, data ),
    viewBenefits: () => axios.get('/company/benefits'),
    deleteBenefits: (id) => axios.post(`/company/delete_benefit`, id),
    updateBenefits: (id) => axios.post(`/company/update_benefit`, id),

    addDiscounts: (data) => axios.post(`/company/add_discount`, data ),
    viewDiscounts: () => axios.get('/company/discount'),
    deleteDiscounts: (id) => axios.post(`/company/delete_discount`, id),
    updateDiscounts: (id) => axios.post(`/company/update_discount`, id),
    benefitCategories: () => axios.get(`/other_benefit_categories?category_for=manager`),
    categoryFaq: (id) => axios.get(`/category_faq?category_id=${id}`),
    benefitMessage: (id, message) => axios.post(`/send_benefit_message/?category_id=${id}&message=${message}`),

    getResponseData: (id, country_id) => axios.get(`/v1/company/free-text-analytics?survey_id=${id}&period=year&country_id=${country_id}`),

    getSurvey: (id, insight_type, responseType, country_id) =>
      axios.get(
        `/v1/company/survey_insight?survey_id=${id}&insight_type=${insight_type}&positive=${responseType}&country_id=${country_id}`,
      ),
  },
  analytics: {
    getTextAnalytics: (data) =>
      axios.get(
        '/v1/company/text-analytics',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getTextAnalyticsInsights: (data) =>
      axios.get(
        '/v1/company/text-analytics-insights',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            filter_type: data.filter_type,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getCompanyMood: (data) =>
      axios.get(
        '/v1/company/company_mood',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getCompanyMoodInsights: (data) =>
      axios.get(
        '/v1/company/company_mood-insights',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            filter_type: data.filter_type,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getLeadingIssues: (data) =>
      axios.get(
        '/v1/company/leading_issues',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            positive: data.positive,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getLeadingIssuesInsights: (data) =>
      axios.get(
        'v1/company/leading_issues-insights',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            filter_type: data.filter_type,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getPriorityIssues: (data) =>
      axios.get(
        '/v1/company/priority_issues_stats',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getPriorityIssuesInsights: (data) =>
      axios.get(
        '/v1/company/priority_issues_insights',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            filter_type: data.filter_type,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getConcerningIssues: (data) =>
      axios.get(
        '/v1/company/concerning_issues_stats',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
    getActivityLevel: (data) =>
      axios.get(
        '/v1/company/activity_over_time',
        data && {
          params: {
            period: data.period,
            compare_previous: data.compare_previous,
            insight_type: data.insight_type,
            department_id: data.department_id,
            branch_id: data.branch_id, 
            country_id: data.country_id
          },
        },
      ),
  },
  dashboard: {
    getDashboard: (data) =>
      axios.get(
        '/v1/company/company_dashboard',
        data && {
          params: {
            period: data.period,
            department_id: data.department_id,
            branch_id: data.branch_id,
            country_id: data.country_id,
          },
        },
      ),
  },
};

export default api;
