import api from '../Util/api';
import {
  GET_PROFILE,
  GET_ALL_DASHBOARD,
  GET_DEPARTMENTS,
  GET_LOCATIONS,
  FILTER_LEADING_ISSUE,
  FILTER_ACTIVITY_LEVEL,
  FILTER_EMPLOYEE_COUNT,
  GET_CONTRACT,
  GET_USER_LIST,
  GET_INVITATION_DETAILS,
  UPDATE_USER_DETAILS,
  GET_USER_DETAILS,
  GET_MANAGER_LIST,
  GET_EMPLOYEE_LIST,
  GET_INVITED_EMPLOYEE_LIST,
} from '../Constants/actionTypes';
import moment from 'moment';
import { hideLoading, showLoading } from './loading.actions';

const start = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
const end = moment().format('YYYY-MM-DD');

export const getProfile = () => (dispatch) =>
  api.company
    .getProfile()
    .then((res) => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data?.data,
      });
      return res.data;
    })
    .catch((err) => err);

const getDashboardBoxes = () => api.company.getDashboardBoxes();
const getDailyMoods = () => api.company.getDailyMoods();
const getDashboardDepartmentStats = (start_date, end_date) =>
  api.company.getDashboardDepartmentStats(start_date, end_date);
const getDashboardActivityOverTime = (start_date, end_date) =>
  api.company.getDashboardActivityOverTime(start_date, end_date);
const getOverallSatisfaction = () => api.company.getOverallSatisfaction();

export const getDepartments = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getDepartments()
      .then((res) => {
        dispatch({ type: GET_DEPARTMENTS, payload: res.data?.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

  export const getLocations = (company_unique_id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getLocations(company_unique_id)
      .then((res) => {
        dispatch({ type: GET_LOCATIONS, payload: res.data?.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

const normalizeDashboardResponses = (data) => {
  const norm = {};
  data.forEach((res) => {
    if (res?.data?.status === 'success' && res?.data?.data) {
      norm[res?.data?.message.replaceAll(' ', '_').toLowerCase()] = res?.data?.data;
    } else return null;
  });

  return norm;
};

export const getAllDashboard = () => (dispatch) =>
  new Promise((resolve, reject) =>
    Promise.all([
      getDashboardBoxes(),
      getDailyMoods(),
      getOverallSatisfaction(),
      getDashboardDepartmentStats(start, end),
      getDashboardActivityOverTime(start, end),
    ])
      .then((res) => {
        dispatch(getDepartments());
        dispatch(getLocations());
        dispatch({ type: GET_ALL_DASHBOARD, payload: normalizeDashboardResponses(res) });
        return resolve(res?.data);
      })
      .catch((err) => reject(err)),
  );

export const getOnlyLeadingIssue = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getLeadingIssues()
      .then((res) => {
        dispatch({ type: FILTER_LEADING_ISSUE, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const filterLeadingIssue = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getLeadingIssues(data)
      .then((res) => {
        dispatch({ type: FILTER_LEADING_ISSUE, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const filterActivityLevel = (start_date = start, end_date = end) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getDashboardActivityOverTime(start_date, end_date)
      .then((res) => {
        dispatch({ type: FILTER_ACTIVITY_LEVEL, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const filterEmployeeCount = (start_date = start, end_date = end) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getDashboardDepartmentStats(start_date, end_date)
      .then((res) => {
        dispatch({ type: FILTER_EMPLOYEE_COUNT, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getOnlyActivityLevel = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getDashboardActivityOverTime(start, end)
      .then((res) => {
        dispatch({ type: FILTER_ACTIVITY_LEVEL, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getOnlyEmployeeCount = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getDashboardDepartmentStats(start, end)
      .then((res) => {
        dispatch({ type: FILTER_EMPLOYEE_COUNT, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getContract = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getContract()
      .then((res) => {
        dispatch({ type: GET_CONTRACT, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getUserList = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getUserList()
      .then((res) => {
        dispatch({ type: GET_USER_LIST, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getManagerList = () => (dispatch) => {
  dispatch(showLoading());
  new Promise((resolve, reject) =>
    api.company
      .getManagerList()
      .then((res) => {
        dispatch({ type: GET_MANAGER_LIST, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getInvitedToHeartbeat = () => (dispatch) => {
  dispatch(showLoading());

  new Promise((resolve, reject) =>
    api.company
      .getInvitedToHeartbeat()
      .then((res) => {
        dispatch({ type: GET_EMPLOYEE_LIST, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};
export const getEmployeeList = () => (dispatch) => {
  dispatch(showLoading());

  new Promise((resolve, reject) =>
    api.company
      .getEmployeeList()
      .then((res) => {
        dispatch({ type: GET_EMPLOYEE_LIST, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getInvitedEmployeeList = () => (dispatch) => {
  dispatch(showLoading());

  new Promise((resolve, reject) =>
    api.company
      .getInvitedEmployeeList()
      .then((res) => {
        dispatch({ type: GET_INVITED_EMPLOYEE_LIST, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err))
      .finally(() => dispatch(hideLoading())),
  );
};

export const getInvitationDetails = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getInvitationDetails()
      .then((res) => {
        dispatch({ type: GET_INVITATION_DETAILS, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const updateUserDetails = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .updateUserDetails(data)
      .then((res) => {
        dispatch({ type: UPDATE_USER_DETAILS, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getUserDetails = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .getUserDetails()
      .then((res) => {
        dispatch({ type: GET_USER_DETAILS, payload: res.data.data });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const createCompanyMasterUser = (data) => () =>
  new Promise((resolve, reject) =>
    api.company
      .createCompanyMasterUser(data)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const createSingleManager = (data) => () =>
  new Promise((resolve, reject) =>
    api.company
      .createSingleManager(data)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const createSingleEmployee = (data) => () =>
  new Promise((resolve, reject) =>
    api.company
      .createSingleEmployee({ ...data, name: data.first_name + ' ' + data.last_name })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

// export const createBulkManager = (data) => () =>
//   new Promise((resolve, reject) =>
//     api.company
//       .createBulkManager(data)
//       .then((res) => {
//         return resolve(res);
//       })
//       .catch((err) => reject(err)),
//   );

// export const createBulkEmployee = (data) => () =>
//   new Promise((resolve, reject) =>
//     api.company
//       .createBulkEmployee(data)
//       .then((res) => {
//         return resolve(res);
//       })
//       .catch((err) => reject(err)),
//   );

export const revokeAccess = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeAccess(id)
      .then((res) => {
        dispatch(getUserList());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const revokeManagerAccess = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeManagerAccess(id)
      .then((res) => {
        dispatch(getManagerList());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const revokeHeartbeatEmployeeAccess = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeEmployeeAccess(id)
      .then((res) => {
        dispatch(getInvitedToHeartbeat());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const revokeHearBeatAccess = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeHearBeatAccess(id)
      .then((res) => {
        dispatch(getInvitedToHeartbeat());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const revokeEmployeeAccess = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeEmployeeAccess(id)
      .then((res) => {
        dispatch(getEmployeeList());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const resendinvite = (id, language) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .resendinvite(id, language)
      .then((res) => {
        dispatch(getInvitedEmployeeList());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const revokeinvitee = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.company
      .revokeAccess(String(id))
      .then((res) => {
        dispatch(getInvitedEmployeeList());
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const inviteEmployee = (formData) => () =>
  new Promise((resolve, reject) =>
    api.company
      .inviteEmployee(formData)
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );
