import { ReactComponent as PieLogo } from '../Assets/Icons/Sidebar/pie.svg';
import { ReactComponent as AnalyticsLogo } from '../Assets/Icons/Sidebar/analytics.svg';
import { ReactComponent as SettingsLogo } from '../Assets/Icons/Sidebar/settings.svg';
import { ReactComponent as ContractLogo } from '../Assets/Icons/Sidebar/billing.svg';
import { ReactComponent as HelpSupport } from '../Assets/Icons/Sidebar/helpSupport.svg';
import { ReactComponent as LogoutLogo } from '../Assets/Icons/Sidebar/logout.svg';
import { ReactComponent as SurveyLogo } from '../Assets/Icons/Sidebar/survey.svg';

// TO DO - revisit isPublic
const genericSidebar = (t) => [
  {
    id: 4,
    title: t('employeeBenefits'),
    url: '/employee-benefits',
    logo: PieLogo,
  },
  // {
  //   id: 5,
  //   title:  t("additionalSupport"),
  //   url: '/additional-support',
  //   logo: PieLogo,
  // },
  {
    id: 6,
    title: t('settings'),
    children: [
      {
        title: t('profileSettings'),
        url: '/settings/profile',
        isPublic: true,
      },
      // {
      //   title: t("userSettings"),
      //   url: '/settings/user',
      // },
      {
        title: t('managerUpload'),
        url: '/settings/manager',
        isPublic: true,
      },
      {
        title: t('employeeUpload'),
        url: '/settings/employee',
        isPublic: true,
      },
    ],
    logo: SettingsLogo,
  },
  {
    id: 7,
    title: t('contract'),
    logo: ContractLogo,
    url: '/contract',
    isPublic: true,
  },
  {
    id: 8,
    title: t('helpAndSupport'),
    logo: HelpSupport,
    url: '/help-and-support',
    isPublic: true,
  },
  {
    id: 9,
    title: t('logout'),
    logo: LogoutLogo,
    url: '/logout',
    isPublic: true,
  },
];

const analyticsSidebar = (isLimitExceeded, t) => [
  {
    id: 1,
    title: t('workforceInsights'),
    url: '/dashboard',
    logo: PieLogo,
    exact: true,
  },
  {
    id: 2,
    title: t('analytics'),
    logo: AnalyticsLogo,
    children: [
      {
        title: t('leadingIssues'),
        url: '/analytics/leading-issues',
      },
      {
        title: t('priorityIssues'),
        url: '/analytics/priority-issues',
      },
      {
        title: t('clinicalInsights'),
        url: '/analytics/therapist-feedback',
      },
      {
        title: t('conversationalAnalytics'),
        url: '/analytics/text-analytics',
      },
      {
        title: t('companyMood'),
        url: '/analytics/company-mood',
      },
      {
        title: t('activityLevel'),
        url: '/analytics/activity-level',
      },
    ],
  },
];

const heartbeatSidebar = (isLimitExceeded, t) => [
  {
    id: 3,
    title: t('heartbeat'),
    logo: SurveyLogo,
    isPublic: true,

    children: [
      {
        title: t('overview'),
        url: '/survey/overview',
        isPublic: true,
      },
      {
        title: t('create'),
        url: '/survey/create',
        isPublic: true,
        tooltipText: !isLimitExceeded ? `${t('surveyLimitReached')}` : null,
      },
    ],
  },
];

export const CompanyDashboardLinks = (isHeartBeat = false, isLimitExceeded = false, isAnalytics, t) => {
  let sidebarData = [...genericSidebar(t)];
  if (isHeartBeat) {
    sidebarData.unshift(...heartbeatSidebar(isLimitExceeded, t));
  }
  if (isAnalytics) {
    sidebarData.unshift(...analyticsSidebar(isLimitExceeded, t));
  }

  return sidebarData;
};
// isOnlyHeartBeat ? heartbeatSidebar(isLimitExceeded) : genericSidebar(isLimitExceeded);

export const CompanyMenuLinks = (t) => [
  {
    id: 1,
    title: t('notifications'),
    url: '/',
  },
  {
    id: 2,
    title: t('profile'),
    url: '/settings/profile',
  },
  {
    id: 3,
    title: t('signOut'),
    url: '/logout',
  },
  {
    id: 4,
    title: t('changeLanguage'),
    open: true,
  },
];

export const language = (t) => [
  {
    id: 1,
    title: t('English'),
    value: 'en',
  },
  {
    id: 2,
    title: t('Greek'),
    value: 'el',
  },
  {
    id: 3,
    title: t('Serbian'),
    value: 'sr',
  },
  // {
  //   id: 4,
  //   title: t('Arabic'),
  //   value: 'ar',
  // },
];
